
import {defineComponent, ref, reactive, UnwrapRef, createVNode, nextTick,onBeforeUnmount} from "vue";
import {
  ExclamationCircleOutlined,
  RollbackOutlined,
  AppstoreOutlined,
  TagsFilled,
} from '@ant-design/icons-vue';
import {TreeDataItem} from 'ant-design-vue/es/tree/Tree';
import {addTagGroup, delAllTags, delTagGroup, getTagOrgTree, tagRename} from "@/api/api_x2server";
import type { TreeProps } from 'ant-design-vue';
import {Modal, message} from 'ant-design-vue';
import propertyTag from "@/views/device/modules/propertyTag.vue";
import logicTag from "@/views/device/modules/logicTag.vue";

interface FormState {
  Name: string;
}

export default defineComponent({
  components: {
    RollbackOutlined,
    TagsFilled,
    propertyTag,
    logicTag,
  },
  props: {
    selectedDev: {
      type: Object,
    }
  },
  setup(props: any, context) {
    const sKeys = ref<(string | number)[]>([''])
    const exKeys = ref<any>([''])
    const treeData = ref<TreeDataItem[]>([{
      title: "全部分组",
      key: '',
    }]);
    const modalTitle = ref<string>("");
    const visible = ref<boolean>(false);
    const formState: UnwrapRef<FormState> = reactive({
      Name: '',
    });
    const confirmLoading = ref<boolean>(false);
    const orgFormRef = ref();
    const rules = ref<any>({
      Name: [
        {required: true, message: '请输入名称', trigger: 'blur'},
      ],
    });
    const currentNode = ref<any>();
    const activeKey = ref<string>("1");
    const propertyTag = ref();
    const logicTag = ref();
    const selectParent = ref<string>("");
    const is_auto = ref<boolean>(false);
    const myLeafIcon = createVNode(AppstoreOutlined)

    const backPage = () => {
      context.emit("backPage")
    }
    const onLoadData = async (treeNode: any) => {
      let parent: string;
      if (treeNode.dataRef.key === '') {
        parent = ""
      } else if (treeNode.dataRef.parent) {
        parent = treeNode.dataRef.parent + "/" + treeNode.dataRef.title
      } else {
        parent = treeNode.dataRef.title
      }
      const children: any = await getTreeChildren(parent);
      treeNode.dataRef.children = children
      if (children.length == 0) {
        treeNode.dataRef.switcherIcon = myLeafIcon;
        treeNode.dataRef.isLeaf = true;
      }
      treeData.value = [...treeData.value];
    }
    const getTreeChildren = async (parent: any) => {
      const res: any = await getTagOrgTree({
        devName: props.selectedDev.Name,
        parent
      });
      return res.map((e: any) => {
        let tree: any = {
          title: e.Name,
          key: e.ID,
          parent: parent,
          children: []
        }
        if (e.HasChild === false) {
          tree.isLeaf = true
          tree.switcherIcon = myLeafIcon;
        }
        return tree
      })
    }
    const onSelect: TreeProps['onSelect'] = async (selectedKeys: (string | number)[], info: any) => {
      currentNode.value = info.node
      if(selectedKeys.length == 0){
        return
      }
      sKeys.value = selectedKeys
      if(info.node.dataRef.parent != undefined){
        selectParent.value = info.node.dataRef.parent != ""?
            info.node.dataRef.parent + "/" + info.node.dataRef.title : info.node.dataRef.title
      }else{
        selectParent.value = ""
      }
      nextTick(() => {
        propertyTag.value.getList(true);
        logicTag.value.getList(true);
      })
    };
    const onExpand = async (expandedKeys: string[]) => {
      exKeys.value = expandedKeys
    };
    const visibleChange = (visible: boolean, node: any) => {
      if (visible) {
        let a: any = document.getElementsByClassName(node.key + "_myTree")[0]
        a.click()
      }
    };
    const onContextMenuClick = (menuKey: string, node: any) => {
      if (menuKey === "1") {
        modalTitle.value = "新增组织"
        visible.value = true;
      } else if (menuKey === "2") {
        modalTitle.value = "修改组织"
        formState.Name = node.dataRef.title
        visible.value = true;
      } else if (menuKey === "3") {
        Modal.confirm({
          title: () => '是否删除该组织?',
          icon: () => createVNode(ExclamationCircleOutlined),
          okText: () => '是',
          okType: 'danger',
          cancelText: () => '否',
          async onOk() {
            const res: any = await delTagGroup({
              devName: props.selectedDev.Name,
              path: node.dataRef.parent != '' ?
                  node.dataRef.parent + "/" + node.dataRef.title : node.dataRef.title
            })
            if (res.Result.toUpperCase() == "OK") {
              message.success("删除成功！")
              removeNode(treeData.value, node.key)
              sKeys.value = ['']
              nextTick(() => {
                propertyTag.value.getList();
                logicTag.value.getList();
              })
            }
          },
        });
      }
    };
    const removeNode = (data: any, treeKey: string, parent?: any) => {
      data.forEach((key: any, index: number) => {
        if (key.key === treeKey) {
          data.splice(index, 1)
          if (parent != null && data.length === 0) {
            parent.isLeaf = true
            parent.switcherIcon = myLeafIcon;
          }
          return
        }
        if (key.children) {
          removeNode(key.children, treeKey, key)
        }
      })
    }
    const closeModal = () => {
      orgFormRef.value.resetFields()
      visible.value = false;
    };
    const handleOk = () => {
      orgFormRef.value
          .validate()
          .then(async () => {
            confirmLoading.value = true;
            let res: any;
            let node = currentNode.value;
            let parent: string = getNodeParent()
            let data = {
              devName: props.selectedDev.Name,
              parent,
              ...formState
            }
            if (modalTitle.value == "新增组织") {
              res = await addTagGroup(data)
            } else {
              res = await tagRename(data)
            }
            confirmLoading.value = false;
            if (res.Result.toUpperCase() == "OK") {
              if (modalTitle.value == "新增组织") {
                if (node.isLeaf) {
                  node.dataRef.isLeaf = false
                  delete node.dataRef.switcherIcon
                  if (node.loaded) {
                    node.dataRef.children.push({
                      title: formState.Name,
                      key: formState.Name,
                      parent: parent,
                      children: [],
                      isLeaf: true,
                      switcherIcon: myLeafIcon,
                    })
                  } else {
                    nextTick(() => {
                      let a: any = document.getElementsByClassName(node.dataRef.key + '_myTree')[0];
                      a.parentNode.parentNode.parentNode.children[1].click()
                    })
                  }
                } else {
                  if (!node.expanded) {
                    let a: any = document.getElementsByClassName(node.dataRef.key + '_myTree')[0];
                    a.parentNode.parentNode.parentNode.children[1].click()
                  }
                  if (node.loaded) {
                    node.dataRef.children.push({
                      title: formState.Name,
                      key: formState.Name,
                      parent: parent,
                      children: [],
                      isLeaf: true,
                      switcherIcon: myLeafIcon,
                    })
                  }
                }
                message.success("添加成功！")
              } else {
                node.dataRef.title = formState.Name
                message.success("修改成功！")
              }
              closeModal();
            }
          })
    }
    const getNodeParent = () => {
      let node = currentNode.value;
      let parent: string;
      if (node && node.dataRef.parent != null) {
        parent = node.dataRef.parent != '' ? node.dataRef.parent + "/" + node.dataRef.title : node.dataRef.title
      } else {
        parent = ""
      }
      return parent
    }
    const cleanTag = async () => {
      Modal.confirm({
        title: () => '是否清空标签?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          let res: any = await delAllTags({
            devId: props.selectedDev.ID
          })
          if (res.Result.toUpperCase() == "OK") {
            propertyTag.value?.getList();
            logicTag.value?.getList();
          }
        },
      });
    }
    let timer: number;
    const autoRefresh = (e:boolean) => {
      if(e){
        timer = setInterval(function () {
          propertyTag.value?.getList();
          logicTag.value?.getList();
        }, 3000);
      }else{
        clearInterval(timer)
      }
    }
    onBeforeUnmount(()=>{
      //销毁前清除定时任务
      clearInterval(timer)
    })
    return {
      sKeys,
      exKeys,
      treeData,
      modalTitle,
      visible,
      confirmLoading,
      orgFormRef,
      formState,
      rules,
      activeKey,
      propertyTag,
      logicTag,
      selectParent,
      is_auto,
      backPage,
      onLoadData,
      onSelect,
      onExpand,
      visibleChange,
      onContextMenuClick,
      closeModal,
      handleOk,
      cleanTag,
      autoRefresh,
    }
  }
})
