import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoForm = _resolveComponent("AutoForm")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    "confirm-loading": _ctx.confirmLoading,
    maskClosable: false,
    onOk: _ctx.handleOk,
    onCancel: _ctx.closeModal,
    class: _normalizeClass(_ctx.disabled?'disabledModal':''),
    width: "700px"
  }, {
    default: _withCtx(() => [
      (_ctx.slotMyForm)
        ? _renderSlot(_ctx.$slots, "myForm", { key: 0 }, undefined, true)
        : _createCommentVNode("", true),
      _createVNode(_component_AutoForm, { ref: "formRef" }, null, 512)
    ]),
    _: 3
  }, 8, ["confirm-loading", "onOk", "onCancel", "class"]))
}