
import {defineComponent, onMounted, ref, createVNode, UnwrapRef, reactive, toRaw, nextTick} from "vue";
import type {TableProps} from 'ant-design-vue';
import {delActions, doAction, getActionConfigSchema, getActionList, setActionsEnable} from "@/api/api_x2server";
import {
  ExclamationCircleOutlined,
  RollbackOutlined,
  DownOutlined,
  SyncOutlined,
} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import AutoModal from "@/components/common/AutoModal.vue";
import {useStore} from "vuex";

const columns = [
  {
    title: '动作名',
    dataIndex: 'Name',
  },
  {
    title: '参数模板',
    dataIndex: 'ParamFormat',
  },
  {
    title: '描述信息',
    dataIndex: 'Des',
  },
  {
    title: '是否启用',
    key: 'Enable',
  },
  {
    title: '动作类型',
    key: 'type',
  },
  {
    title: '操作',
    align: 'center',
    width: '180px',
    key: 'operation',
  }
]
export default defineComponent({
  components: {
    RollbackOutlined,
    DownOutlined,
    AutoModal,
    SyncOutlined,
  },
  props: {
    selectedDev: {
      type: Object,
    }
  },
  setup(props, context) {
    const store = useStore();
    const autoForm = ref();
    const autoForm2 = ref();
    const title = ref<string>();
    const visible = ref<boolean>(false)
    const visible2 = ref<boolean>(false)
    const queryName = ref<string>("")
    const loading = ref<boolean>(false)
    const btLoading = ref<boolean>(false)
    const dataSource = ref<any>([])
    const sRowKeys = ref<any>([])
    const pagination = ref<any>({
      total: 0,
      current: 1,
      pageSize: 10,
    })
    onMounted(() => {
      getList()
    })
    const selectedRecord = ref<any>()

    const getList = async () => {
      let res: any = await getActionList({
        Count: pagination.value?.pageSize,
        Pos: (pagination.value?.current - 1) * pagination.value?.pageSize,
        DevID: props.selectedDev?.ID,
        QueryName: queryName.value,
      })
      dataSource.value = res.Items
      pagination.value!.total = res.TotalCount
    }
    const handleTableChange: TableProps['onChange'] = (
      pag: any,
    ) => {
      pagination.value = pag
      getList()
    };
    const onSelectChange = (selectedRowKeys: any) => {
      sRowKeys.value = selectedRowKeys;
    };
    const customRow = (record: any) => {
      return {
        onClick: (event: any) => {
          let index = sRowKeys.value.indexOf(record.Name);
          if (index == -1) {
            sRowKeys.value.push(record.Name)
          } else {
            sRowKeys.value.splice(index, 1)
          }
        },
      }
    }

    const toDelete = (name: any) => {
      if (name) {
        name = [name]
      } else {
        if (sRowKeys.value.length == 0) {
          message.error("请先选择一行数据！")
          return
        }
        name = sRowKeys.value
      }
      Modal.confirm({
        title: () => '是否删除该动作?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await delActions({
            DevID: props.selectedDev?.ID,
            Name: name
          })
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    }
    const handleMenuClick = (e: any) => {
      if (sRowKeys.value.length == 0) {
        message.error("请先选择一行数据！")
        return
      }
      let name = sRowKeys.value
      let txt = e.key == "true" ? '启用' : '禁用'
      Modal.confirm({
        title: () => '是否' + txt + '该动作?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await setActionsEnable({
            DevID: props.selectedDev?.ID,
            Name: name,
            Enable: e.key
          })
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    };

    const toPlay = async (record: any) => {
      selectedRecord.value = record
      if (record.Type === 0) {
        Modal.confirm({
          title: () => '是否运行【' + selectedRecord.value.Name + '】该动作?',
          icon: () => createVNode(ExclamationCircleOutlined),
          okText: () => '是',
          okType: 'danger',
          cancelText: () => '否',
          async onOk() {
            const res: any = await doAction({
              DevID: props.selectedDev?.ID,
              Action: selectedRecord.value.Name,
            })
            if (res.Result.toUpperCase() == "OK") {
              message.success("运行成功！")
            }
          },
        });
      }
      if (record.Type === 1) {
        const res: any = await getActionConfigSchema({
          devId: props.selectedDev?.ID,
          name: record?.Name
        })
        let basicItems: any = [];
        if (res.Schema.Items) {
          res.Schema.Items = basicItems.concat(res.Schema?.Items)
        } else {
          res.Schema.Items = basicItems
        }
        params.value = {
          configSchema: res,
          devId: props.selectedDev?.ID,
          action: selectedRecord.value.Name,
          type: "doInnerAction",
          disabled: false
        }
        store.commit("setConfigParams", params);
        visible2.value = true
        autoForm2.value.initForm()
      }

    }

    const backPage = () => {
      context.emit("backPage")
    }

    const params = ref<any>();
    const toEditAction = async (record?: any, disabled?: boolean) => {
      const res: any = await getActionConfigSchema({
        devId: props.selectedDev?.ID,
        name: record?.Name
      })
      let basicItems = [{
        IsReq: true,
        Key: "Name",
        T: "String",
        Title: "动作名",
        V: record?.Name
      }, {
        IsReq: false,
        Key: "Des",
        T: "String",
        Title: "描述",
        V: record?.Des
      }];
      if (res.Schema.Items) {
        res.Schema.Items = basicItems.concat(res.Schema?.Items)
      } else {
        res.Schema.Items = basicItems
      }
      params.value = {
        configSchema: res,
        devId: props.selectedDev?.ID,
        type: record ? "editAction" : "addAction",
        disabled
      }
      store.commit("setConfigParams", params);
      title.value = disabled ? "查看动作" : "动作配置"
      visible.value = true
      autoForm.value.initForm()
    }
    return {
      title,
      visible,
      visible2,
      queryName,
      loading,
      columns,
      dataSource,
      pagination,
      sRowKeys,
      autoForm,
      autoForm2,
      btLoading,
      selectedRecord,
      getList,
      handleTableChange,
      backPage,
      onSelectChange,
      customRow,
      toDelete,
      handleMenuClick,
      toPlay,
      toEditAction,
    }
  }
})
