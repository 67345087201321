
import {defineComponent, ref, createVNode} from "vue"
import {addDevOrg, getDevOrgTree} from "@/api/api_x2server";
import {TreeDataItem} from 'ant-design-vue/es/tree/Tree';
import type { TreeProps } from 'ant-design-vue';
import {
  AppstoreOutlined,
} from '@ant-design/icons-vue';
import {message} from 'ant-design-vue';

export default defineComponent({
  setup(props, context) {
    const visible = ref<boolean>(false)
    const confirmLoading = ref<boolean>(false)
    const treeData = ref<TreeDataItem[]>([]);
    const sKeys = ref<(string | number)[]>([])
    const exKeys = ref<any>([])
    const currentNode = ref<any>();
    let devName = "";
    const myLeafIcon = createVNode(AppstoreOutlined)

    const handleOk = async () => {
      if (currentNode.value == null) {
        message.error("请选择分组！")
        return
      }
      let node = currentNode.value.dataRef
      const res: any = await addDevOrg({
        devName,
        orgPath: node.parent == "" ? node.title : node.parent + "/" + node.title
      })
      if (res.Result.toUpperCase() == "OK") {
        message.success("修改成功！")
        closeModal()
        context.emit("success")
      }
    }
    const closeModal = () => {
      visible.value = false
      sKeys.value = []
      exKeys.value = []
      currentNode.value = null
    }

    const onLoadData = async (treeNode: any) => {
      let parent: string;
      if (treeNode.dataRef.key === '') {
        parent = ""
      } else if (treeNode.dataRef.parent) {
        parent = treeNode.dataRef.parent + "/" + treeNode.dataRef.title
      } else {
        parent = treeNode.dataRef.title
      }
      const children: any = await getTreeChildren(parent);
      treeNode.dataRef.children = children
    }
    const getTreeChildren = async (parent: any) => {
      const res: any = await getDevOrgTree({parent});
      return res.map((e: any) => {
        let tree: any = {
          title: e.Name,
          key: e.ID,
          parent: parent,
          children: []
        }
        if (e.HasChild === false) {
          tree.isLeaf = true
          tree.switcherIcon = myLeafIcon;
        }
        return tree
      })
    }
    const onSelect: TreeProps['onSelect'] = (selectedKeys: (string | number)[], info: any) => {
      if(selectedKeys.length == 0){
        return
      }
      sKeys.value = selectedKeys
      currentNode.value = info.node
    };
    const onExpand = async (expandedKeys: string[]) => {
      exKeys.value = expandedKeys
    };

    const init = async (data: string) => {
      visible.value = true
      devName = data
      const children: any = await getTreeChildren("");
      treeData.value = children
    }

    return {
      visible,
      confirmLoading,
      treeData,
      sKeys,
      exKeys,
      handleOk,
      closeModal,
      onLoadData,
      onSelect,
      onExpand,
      init,
    }
  }
})
