
import setDevOrg from "@/views/device/modules/setDevOrg.vue";
import devAction from "./modules/devAction.vue"
import devTag from "./modules/devTag.vue"
import {defineComponent, onMounted, ref, createVNode, UnwrapRef, reactive, watch, nextTick} from "vue";
import {
  ExclamationCircleOutlined,
  AppstoreOutlined,
  SyncOutlined,
} from '@ant-design/icons-vue';
import {
  getDevOrgTree,
  getDevList,
  delDev,
  setDevicesEnable,
  addDevGroup,
  updateDevGroup,
  delDevGroup, getDevConfigSchema, delDevOrg, cloneToVirtual
} from "@/api/api_x2server";
import {TreeDataItem} from 'ant-design-vue/es/tree/Tree';
import type {TreeProps} from 'ant-design-vue';
import {Modal, message} from 'ant-design-vue';
import {useStore} from "vuex";
import {useRouter, useRoute} from 'vue-router'

interface FormState {
  Name: string;
}

export default defineComponent({
  components: {
    setDevOrg,
    devTag,
    devAction,
    SyncOutlined,
  },
  setup() {
    const pageType = ref<number>(1)
    const imageUrl = ref<string>(sessionStorage.getItem("Host") + "/File/GetImageByDevType?type=");
    const sKeys = ref<(string | number)[]>([''])
    const exKeys = ref<any>([''])
    const treeData = ref<TreeDataItem[]>([{
      title: "全部分组",
      key: '',
    }]);
    const queryName = ref<string>("");
    const total = ref<number>(0);
    const current = ref<number>(1);
    const pageSize = ref<number>(12);
    const devList = ref<any>();
    const selectedDev = ref<number>();

    const currentNode = ref<any>();
    const modalTitle = ref<string>("");
    const visible = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
      Name: '',
    });
    const rules = ref<any>({
      Name: [
        {required: true, message: '请输入名称', trigger: 'blur'},
      ],
    });
    const store = useStore();
    const router = useRouter()
    const route = useRoute()
    const setDevOrgRef = ref()
    const myLeafIcon = createVNode(AppstoreOutlined)

    onMounted(() => {
      getList()
    })
    watch(route, () => {
      pageType.value = 1
    })
    const onLoadData = async (treeNode: any) => {
      let parent: string;
      if (treeNode.dataRef.key === '') {
        parent = ""
      } else if (treeNode.dataRef.parent) {
        parent = treeNode.dataRef.parent + "/" + treeNode.dataRef.title
      } else {
        parent = treeNode.dataRef.title
      }
      const children: any = await getTreeChildren(parent);
      treeNode.dataRef.children = children
      if (children.length == 0) {
        treeNode.dataRef.switcherIcon = myLeafIcon;
        treeNode.dataRef.isLeaf = true;
      }
      treeData.value = [...treeData.value];
    }
    const getTreeChildren = async (parent: any) => {
      const res: any = await getDevOrgTree({parent});
      return res.map((e: any) => {
        let tree: any = {
          title: e.Name,
          key: e.ID,
          parent: parent,
          children: []
        }
        if (e.HasChild === false) {
          tree.isLeaf = true
          tree.switcherIcon = myLeafIcon;
        }
        return tree
      })
    }
    const onSelect: TreeProps['onSelect'] = async (selectedKeys: (string | number)[], info: any) => {
      currentNode.value = info.node
      if (selectedKeys.length == 0) {
        return
      }
      sKeys.value = selectedKeys
      current.value = 1
      getList()
    };
    const onExpand = async (expandedKeys: string[]) => {
      exKeys.value = expandedKeys
    };

    const getNodeParent = () => {
      let node = currentNode.value;
      let parent: string;
      if (node && node.dataRef.parent != null) {
        parent = node.dataRef.parent != '' ? node.dataRef.parent + "/" + node.dataRef.title : node.dataRef.title
      } else {
        parent = ""
      }
      return parent
    }

    const getList = async () => {
      let parent: string = getNodeParent()
      const res: any = await getDevList({
        Count: pageSize.value,
        Pos: (current.value - 1) * pageSize.value,
        QueryName: queryName.value,
        parent
      })
      total.value = res.TotalCount
      devList.value = res.Items
    }
    const onShowSizeChange = (c: number, p: number) => {
      getList()
    };

    const deleteDev = (id: number) => {
      Modal.confirm({
        title: () => '是否删除该设备?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await delDev({id})
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    };
    const setDev = async (dev: any) => {
      const res: any = await setDevicesEnable({
        Enable: !dev.Enable,
        DevID: [dev.ID],
      })
      if (res.Result.toUpperCase() == "OK") {
        if (dev.Enable) {
          message.success("禁用成功！")
        } else {
          message.success("启用成功！")
        }
        getList();
      }
    };
    const onContextMenuClick = (menuKey: string, node: any) => {
      if (menuKey === "1") {
        modalTitle.value = "新增组织"
        visible.value = true;
      } else if (menuKey === "2") {
        modalTitle.value = "修改组织"
        formState.Name = node.dataRef.title
        visible.value = true;
      } else if (menuKey === "3") {
        Modal.confirm({
          title: () => '是否删除该组织?',
          icon: () => createVNode(ExclamationCircleOutlined),
          okText: () => '是',
          okType: 'danger',
          cancelText: () => '否',
          async onOk() {
            const res: any = await delDevGroup({
              path: node.dataRef.parent != '' ?
                node.dataRef.parent + "/" + node.dataRef.title : node.dataRef.title
            })
            if (res.Result.toUpperCase() == "OK") {
              message.success("删除成功！")
              removeNode(treeData.value, node.key)
              sKeys.value = ['']
              getList()
            }
          },
        });
      }
    };
    const removeNode = (data: any, treeKey: string, parent?: any) => {
      data.forEach((key: any, index: number) => {
        if (key.key === treeKey) {
          data.splice(index, 1)
          if (parent != null && data.length === 0) {
            parent.isLeaf = true
            parent.switcherIcon = myLeafIcon;
          }
          return
        }
        if (key.children) {
          removeNode(key.children, treeKey, key)
        }
      })
    }
    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          confirmLoading.value = true;
          let res: any;
          let node = currentNode.value;
          let parent: string = getNodeParent()
          if (modalTitle.value == "新增组织") {
            res = await addDevGroup({
              parent,
              ...formState
            })
          } else {
            res = await updateDevGroup({
              parent,
              ...formState
            })
          }
          confirmLoading.value = false;
          if (res.Result.toUpperCase() == "OK") {
            if (modalTitle.value == "新增组织") {
              if (node.isLeaf) {
                node.dataRef.isLeaf = false
                delete node.dataRef.switcherIcon
                if (node.loaded) {
                  node.dataRef.children.push({
                    title: formState.Name,
                    key: formState.Name,
                    parent: parent,
                    children: [],
                    isLeaf: true,
                    switcherIcon: myLeafIcon,
                  })
                } else {
                  nextTick(() => {
                    let a: any = document.getElementsByClassName(node.dataRef.key + '_myTree')[0];
                    a.parentNode.parentNode.parentNode.children[1].click()
                  })
                }
              } else {
                if (!node.expanded) {
                  let a: any = document.getElementsByClassName(node.dataRef.key + '_myTree')[0];
                  a.parentNode.parentNode.parentNode.children[1].click()
                }
                if (node.loaded) {
                  node.dataRef.children.push({
                    title: formState.Name,
                    key: formState.Name,
                    parent: parent,
                    children: [],
                    isLeaf: true,
                    switcherIcon: myLeafIcon,
                  })
                }
              }
              message.success("添加成功！")
            } else {
              node.dataRef.title = formState.Name
              message.success("修改成功！")
            }
            closeModal();
          }
        })
    }
    const closeModal = () => {
      formRef.value.resetFields()
      visible.value = false;
    };
    const visibleChange = (visible: boolean, node: any) => {
      if (visible) {
        let a: any = document.getElementsByClassName(node.key + "_myTree")[0]
        a.click()
      }
    };
    const toEditDev = async (item: any) => {
      let res: any = await getDevConfigSchema({id: item.ID})
      let basicItems = [{
        IsReq: true,
        Key: "Name",
        T: "String",
        Title: "设备名",
        V: item.Name
      }, {
        IsReq: false,
        Key: "Code",
        T: "String",
        Title: "设备编码",
        V: item.Code
      }, {
        IsReq: false,
        Key: "Des",
        T: "String",
        Title: "设备描述",
        V: item.Des
      }]
      if (res.Schema.Items) {
        res.Schema.Items = basicItems.concat(res.Schema?.Items)
      } else {
        res.Schema.Items = basicItems
      }

      store.commit("setConfigParams", {
        id: item.ID,
        title: item.Name,
        icon: imageUrl.value + item.Type,
        introduce: res.Schema.Des,
        configSchema: res
      });
      router.push({
        name: "configurationView", params: {
          type: "updateDev",
          configType: item.Name
        }
      })
    };

    const toTagView = (dev: number) => {
      selectedDev.value = dev;
      pageType.value = 2;
    }
    const toActionView = (dev: number) => {
      selectedDev.value = dev;
      pageType.value = 3;
    }
    const toSetDevOrg = async (key: string, dev: any) => {
      if (key == "1") {
        setDevOrgRef.value.init(dev.Name)
      }
      if (key == "2") {
        const res: any = await delDevOrg({
          devName: dev.Name,
        })
        if (res.Result.toUpperCase() == "OK") {
          message.success("移出分组成功！")
          getList()
        }
      }
      if (key == "3") {
        const res: any = await cloneToVirtual({DevName: [dev.Name]})
        if (res.Result.toUpperCase() == "OK") {
          message.success("克隆虚拟设备成功！")
          getList()
        }
      }
    }
    return {
      pageType,
      imageUrl,
      sKeys,
      exKeys,
      treeData,
      pageSize,
      queryName,
      total,
      current,
      devList,
      modalTitle,
      visible,
      confirmLoading,
      formRef,
      formState,
      rules,
      selectedDev,
      setDevOrgRef,
      getList,
      onLoadData,
      onExpand,
      onSelect,
      onShowSizeChange,
      deleteDev,
      setDev,
      onContextMenuClick,
      visibleChange,
      handleOk,
      closeModal,
      toEditDev,
      toTagView,
      toActionView,
      toSetDevOrg,
    }
  }
})
