import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_directory_tree = _resolveComponent("a-directory-tree")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "设备分组",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    "confirm-loading": _ctx.confirmLoading,
    maskClosable: false,
    onOk: _ctx.handleOk,
    onCancel: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      (_ctx.visible && _ctx.treeData.length != 0)
        ? (_openBlock(), _createBlock(_component_a_directory_tree, {
            key: 0,
            "load-data": _ctx.onLoadData,
            "tree-data": _ctx.treeData,
            selectedKeys: _ctx.sKeys,
            expandedKeys: _ctx.exKeys,
            expandAction: "dblclick",
            showLine: true,
            showIcon: false,
            onSelect: _ctx.onSelect,
            onExpand: _ctx.onExpand
          }, null, 8, ["load-data", "tree-data", "selectedKeys", "expandedKeys", "onSelect", "onExpand"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, "暂无分组..."))
    ]),
    _: 1
  }, 8, ["visible", "confirm-loading", "onOk", "onCancel"]))
}