
import {defineComponent, useSlots, ref} from "vue";
import {useStore} from "vuex";
import AutoForm from "@/components/common/AutoForm.vue";
import {getResultItems} from "@/components/common/autoFormFun";
import {
  addAction,
  addLogicTag,
  addTag,
  doInnerAction,
  editAction,
  editLogicTag,
  editTag,
  writeTag
} from "@/api/api_x2server";
import {message} from 'ant-design-vue';

export default defineComponent({
  components: {AutoForm},
  setup(props: any, context) {
    const formRef = ref();
    const store = useStore();
    const confirmLoading = ref<boolean>(false);
    const disabled = ref<boolean>(false);
    //判断<slot/>是否有传值
    const slotMyForm = !!useSlots().myForm;
    const slotFooter = !!useSlots().footer;

    const handleOk = () => {
      formRef.value.validate()
        .then(async (res: any) => {
          confirmLoading.value = true;
          let configParams = store.getters.getConfigParams;
          let Schema = configParams.configSchema.Schema;
          let devId = configParams.devId;
          let type = configParams.type;
          formRef.value.coverItems(Schema.Items)
          let result: any;

          let a = Schema.Items.filter((e: any) => (
            e.Key != 'Name' && e.Key != 'Des'
          ))
          let Items: any = getResultItems(a)
          let data: any = {
            devId,
            Name: res.Name,
            Des: res.Des,
            Config: {
              Key: Schema.Key,
              Items
            }
          }
          if (type === "addAction") {
            result = await addAction(data);
          } else if (type === "editAction") {
            result = await editAction(data);
          } else if (type === "addTag") {
            data.tagOrg = configParams.tagOrg;
            result = await addTag(data);
          } else if (type === "editTag") {
            data.ID = configParams.id;
            result = await editTag(data);
          } else if (type === "addLogicTag") {
            data.tagOrg = configParams.tagOrg;
            result = await addLogicTag(data);
          } else if (type === "editLogicTag") {
            data.ID = configParams.id;
            result = await editLogicTag(data);
          } else if (type === "doInnerAction") {
            result = await doInnerAction({
              devId,
              Action: configParams.action,
              Config: {
                Key: Schema.Key,
                Items
              }
            });
          }
          if (type === "setTagValue") {
            result = await writeTag({
              devId,
              tagId: configParams.id,
              value: Items.find((e: any) => e.Key == "V").V
            });
          }

          if (result.Result.toUpperCase() == "OK") {
            if (type === "doInnerAction") {
              if(result.Msg){
                message.success(result.Msg)
              }else{
                message.success("运行成功！")
              }
            } else {
              message.success("配置成功！")
            }
            confirmLoading.value = false;
            context.emit("success")
            closeModal()
          }
        })
    }
    const closeModal = () => {
      formRef.value.resetFields();
    }
    const initForm = () => {
      disabled.value = store.getters.getConfigParams.disabled
      formRef.value?.init()
    }
    return {
      confirmLoading,
      formRef,
      disabled,
      slotMyForm,
      slotFooter,
      handleOk,
      closeModal,
      initForm
    }
  }
})
