
import {defineComponent, onMounted, ref, createVNode, UnwrapRef, reactive, toRaw, nextTick} from "vue";
import type {TableProps} from 'ant-design-vue';
import {
  delTag, delTagsToOrg, downloadTagCSVFile,
  downloadUrlList,
  getTagConfigSchema,
  getTagList, readTagCSVFile,
  setTagsEnable
} from "@/api/api_x2server";
import {useStore} from "vuex";
import AutoModal from "@/components/common/AutoModal.vue";
import {
  ExclamationCircleOutlined,
  ZoomInOutlined,
  CopyOutlined,
  EditOutlined,
  SyncOutlined,
} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import {dateFormat} from "@/utils/common";
import setTagOrg from "@/views/device/modules/setTagOrg.vue";

const columns = [
  {
    title: '标签ID',
    dataIndex: 'ID',
    width: 80
  },
  {
    title: '标签名',
    dataIndex: 'Name',
  },
  {
    title: '描述信息',
    dataIndex: 'Des',
  },
  {
    title: '是否启用',
    key: 'Enable',
    width: 90
  },
  {
    title: '是否可写',
    key: 'CanWrite',
    width: 90
  },
  {
    title: 'Tag值类型',
    dataIndex: 'VType',
    width: 100
  },
  {
    title: 'Tag值',
    key: 'V',
    width: 90
  },
  {
    title: '属性单位',
    dataIndex: 'U',
    width: 90
  },
  {
    title: '状态码',
    dataIndex: 'StatusCode',
    width: 80
  },
  {
    title: '更新时间',
    dataIndex: 'Time',
    width: 150
  },
  {
    title: '操作',
    align: 'center',
    width: 180,
    fixed: 'right',
    key: 'operation',
  }
]
export default defineComponent({
  props: {
    selectedDev: {
      type: Object,
    },
    selectParent: {
      type: String
    }
  },
  components: {
    AutoModal,
    ZoomInOutlined,
    CopyOutlined,
    EditOutlined,
    setTagOrg,
    SyncOutlined,
  },
  setup(props: any, context) {
    const autoForm = ref();
    const setTagOrgRef = ref();
    const queryName = ref<string>();
    const rowSelection = ref<any>({
      selectedRowKeys: [],
      onChange: (selectedRowKeys: any) => {
        rowSelection.value.selectedRowKeys = selectedRowKeys;
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.Name === 'State', // Column configuration not to be checked
      }),
    })
    const dataSource = ref<any>([])
    const pagination = ref<any>({
      total: 0,
      current: 1,
      pageSize: 10,
    })
    const sortType = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const store = useStore();
    const title = ref<string>();
    const visible = ref<boolean>();
    onMounted(() => {
      getList()
    })
    const getList = async (onSelect?: boolean) => {
      if (onSelect) {
        pagination.value.current = 1
      }
      loading.value = true
      let res: any = await getTagList({
        pageSize: pagination.value?.pageSize,
        start: (pagination.value?.current - 1) * pagination.value?.pageSize,
        DevID: props.selectedDev?.ID,
        QueryName: queryName.value,
        parent: props.selectParent,
        sortType: sortType.value ? 1 : 0,
      })
      res.Items.forEach((key: any) => {
        if (key.V && typeof key.V == "object") {
          key.V = JSON.stringify(key.V)
        }
      })
      dataSource.value = res.Items
      pagination.value!.total = res.TotalCount
      rowSelection.value.selectedRowKeys = []
      loading.value = false
    }
    const handleTableChange: TableProps['onChange'] = (
        pag: any,
    ) => {
      pagination.value = pag
      getList()
    };
    const customRow = (record: any) => {
      return {
        onClick: (event: any) => {
          let index = rowSelection.value.selectedRowKeys.indexOf(record.ID);
          if (index == -1 && record.Name != "State") {
            rowSelection.value.selectedRowKeys.push(record.ID)
          } else {
            rowSelection.value.selectedRowKeys.splice(index, 1)
          }
        },
      }
    }
    const params = ref<any>();
    const toEditTag = async (record: any, disabled?: boolean) => {
      const res: any = await getTagConfigSchema({
        devId: props.selectedDev?.ID,
        id: record?.ID
      })
      let basicItems = [{
        IsReq: true,
        Key: "Name",
        T: "String",
        Title: "标签名",
        V: record?.Name
      }, {
        IsReq: false,
        Key: "Des",
        T: "String",
        Title: "描述",
        V: record?.Des
      }];
      if (res.Schema.Items) {
        res.Schema.Items = basicItems.concat(res.Schema?.Items)
      } else {
        res.Schema.Items = basicItems
      }

      res.Schema.Items.forEach((key: any) => {
        //是否数组下拉菜单修改时，改变数据长度是否可编辑
        if (key.Key == "TagIsArray") {
          key.changeFun = (e: any, items: any) => {
            let lengthItem = items.find((e: any) => e.Key == "Length");
            if (lengthItem) {
              if (e) {
                lengthItem.disabled = false
              } else {
                lengthItem.disabled = true
              }
            }
          }
        }
        //数据长度不可编辑
        if (key.Key == "Length") {
          key.disabled = true
        }
      })

      params.value = {
        id: record?.ID,
        configSchema: res,
        devId: props.selectedDev?.ID,
        type: record ? "editTag" : "addTag",
        disabled,
        tagOrg: props.selectParent
      }
      store.commit("setConfigParams", params);
      title.value = disabled ? "查看标签" : "标签配置"
      visible.value = true
      autoForm.value.initForm()
    }
    const toEditValue = async (record: any) => {
      const res: any = {
        Schema: {
          Items: [{
            IsReq: false,
            Key: "DevName",
            T: "showText",
            Title: "设备名称",
            V: props.selectedDev?.Name
          }, {
            IsReq: false,
            Key: "Name",
            T: "showText",
            Title: "标签名",
            V: record?.Name
          }, {
            IsReq: false,
            Key: "VType",
            T: "showText",
            Title: "Tag值类型",
            V: record?.VType
          }, {
            IsReq: true,
            Key: "V",
            T: record.VType == "Boolean" ? "Boolean" : "String",
            Title: "Tag值",
            V: record?.V
          }]
        }
      }

      params.value = {
        id: record?.ID,
        configSchema: res,
        devId: props.selectedDev?.ID,
        type: "setTagValue",
      }
      store.commit("setConfigParams", params);
      title.value = "设置Tag值"
      visible.value = true
      autoForm.value.initForm()
    }
    const toDelete = (id?: any) => {
      if (id) {
        id = [id]
      } else {
        if (rowSelection.value.selectedRowKeys.length == 0) {
          message.error("请先选择一行数据！")
          return
        }
        id = rowSelection.value.selectedRowKeys
      }
      Modal.confirm({
        title: () => '是否删除该属性标签?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await delTag({
            DevID: props.selectedDev?.ID,
            id: id
          })
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    }

    const handleMenuClick = (e: any) => {
      if (rowSelection.value.selectedRowKeys.length == 0) {
        message.error("请先选择一行数据！")
        return
      }
      let id = rowSelection.value.selectedRowKeys
      let txt = e.key=="true"?'启用':'禁用'
      Modal.confirm({
        title: () => '是否'+txt+'该标签?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await setTagsEnable({
            DevID: props.selectedDev?.ID,
            id,
            Enable: e.key
          })
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    };
    const copyUrl = (record: any) => {
      let code = sessionStorage.getItem("Host") + "/Dev/GetTag?DevName=" + record.DevName + "&TagName=" + record.Name;
      const handleCopy = (e: ClipboardEvent) => {
        // clipboardData 可能是 null
        e.clipboardData && e.clipboardData.setData('text/plain', code);
        e.preventDefault();
        // removeEventListener 要传入第二个参数
        document.removeEventListener('copy', handleCopy);
      };
      document.addEventListener('copy', handleCopy);
      document.execCommand('copy');
      message.success("拷贝成功！")
    }
    const downloadURL = async () => {
      let res: any = await downloadUrlList({
        devId: props.selectedDev?.ID,
      });
      let url = window.URL.createObjectURL(new Blob([res]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', "TagURLList_" + dateFormat(new Date()) + ".csv")
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a)
    }
    const downloadCSV = async () => {
      let res: any = await downloadTagCSVFile({
        devId: props.selectedDev?.ID,
      });
      let url = window.URL.createObjectURL(new Blob([res]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', "TagCSVFile_" + dateFormat(new Date()) + ".csv")
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a)
    }
    const customRequest = async (info: any) => {
      const res: any = await readTagCSVFile(props.selectedDev?.ID, info.file);
      if (res.Result.toUpperCase() == "OK") {
        getList()
        message.success("导入成功！")
      }
    }

    const toSetTagOrg = () => {
      if (rowSelection.value.selectedRowKeys.length == 0) {
        message.error("请先选择一行数据！")
        return
      }
      setTagOrgRef.value.init({
        devName: props.selectedDev?.Name,
        tagIds: rowSelection.value.selectedRowKeys.join(","),
      })
    }
    const delEditTagOrg = async () => {
      if (rowSelection.value.selectedRowKeys.length == 0) {
        message.error("请先选择一行数据！")
        return
      }
      const res: any = await delTagsToOrg({
        DevID: props.selectedDev?.ID,
        id: rowSelection.value.selectedRowKeys
      })
      if (res.Result.toUpperCase() == "OK") {
        message.success("移出分组成功！")
        getList()
      }
    }

    return {
      setTagOrgRef,
      queryName,
      columns,
      rowSelection,
      dataSource,
      pagination,
      loading,
      title,
      visible,
      autoForm,
      sortType,
      getList,
      handleTableChange,
      customRow,
      toEditTag,
      toEditValue,
      toDelete,
      handleMenuClick,
      copyUrl,
      downloadURL,
      downloadCSV,
      customRequest,
      toSetTagOrg,
      delEditTagOrg,
    }
  }
})
